import { BaseMock } from '~/types/mock'

const DevopsWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'We have built an efficient rapid mobile app development process, allowing us to create and deliver your platform on time and budget.',
  },
  {
    title: 'Extensive expertise',
    description:
      'Having built multiple web and mobile solutions, our experienced team has accumulated profound market and industry knowledge.',
  },
  {
    title: 'Full control over product development',
    description:
      'Our mobile app development process is fully transparent, which is guaranteed by timely reports and excellent communication.',
  },
  {
    title: 'Secure and scalable solution',
    description:
      'Applying the best DevOps practices and our vast expertise in web development, we ensure the high scalability and security of your mobile app.',
  },
  {
    title: 'An enthusiastic team that cares',
    description:
      'We are proud of our result-oriented team that values communication and flexibility and focuses on long-lasting high-value relationships.',
  },
  {
    title: 'Support after launch',
    description:
      'With Codica’s mobile app development services, our work doesn’t stop when your mobile app goes live. Our reliable support team will ensure your mobile web solution runs smoothly.',
  },
]

export default DevopsWhyChoose
