// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "t_cG";
export var qaBenefitsSection = "t_cv";
export var qaBusinessSection = "t_cw";
export var qaCtaSection = "t_cC";
export var qaCtaSection__cta = "t_cD";
export var qaDomainExpertiseSection = "t_cz";
export var qaResultSection = "t_cy";
export var qaServicesSection = "t_cr";
export var qaTechStackSection = "t_cB";
export var qaTestimonialPost = "t_cx";
export var qaTestingServicesSection = "t_cs";
export var qaVideoClientSection = "t_cH";
export var qaWhenDoYouNeedSection = "t_ct";
export var qaWhyChooseSection = "t_cF";