import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import ServicesQualityAssurance from '~/views/Services/QualityAssurance/QualityAssurance.view'

const ServicesServicesQualityAssurancePage = () => <ServicesQualityAssurance />

export default ServicesServicesQualityAssurancePage

export const query = graphql`
  query {
    strapiService(page_name: { eq: "Quality Assurance" }) {
      ...MetaTagInformationServices
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiService>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/quality-assurance/"
    />
  )
}
