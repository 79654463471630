import { DevExpertiseCompanyMock } from '~/types/mock'

const QADevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '9+',
    text: 'years in the market',
  },
  {
    title: '50+',
    text: 'successful projects',
  },
  {
    title: '+56$',
    text: 'mln raised by our startup clients',
  },
  {
    title: '60+',
    text: 'team size',
  },
]

export default QADevExpertise
