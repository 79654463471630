import { FAQ } from '~/types/data-array'

const QAFAQ: FAQ[] = [
  {
    question:
      'What is the typical timeframe for completing quality assurance testing for a project?',
    answer:
      'Our experienced software quality assurance team Codica uses only the top testing tools to make the time to product delivery as short as possible. In most cases, for small projects, QA takes from 2 to 4 weeks. For bigger and more complex projects, this could amount to 4-8 weeks or even more.',
  },
  {
    question: 'How much does quality assurance testing cost?',
    answer:
      'The cost of our QA services ranges from $5,000 to $30,000, depending on the size, complexity, and requirements of your project. This includes the number and types of testing activities, level of test automation, and needs for maintenance and support.',
  },
  {
    question: 'What factors influence the cost of QA services?',
    answer:
      'Our foremost cost-influencing aspects of the services that we provide are the size and complexity of your project, specific testing activities, the degree of test automation, time, effort, ongoing maintenance and support requirements.',
  },
  {
    question: 'Can QA services be scaled to fit smaller or larger projects?',
    answer:
      'Absolutely true. At Codica, we have a very efficient and scaled QA testing process. Whether you have a smaller project with lesser complexity or a more complex and time-consuming one, we can increase or decrease the level of our QA services to fall in line with your changing business needs.',
  },
  {
    question:
      'How does the testing process adapt to ongoing changes in the project?',
    answer:
      'Our QA process involves feedback loops and ongoing testing throughout the development lifecycle. We strictly adhere to all industry standards, guidelines, and best practices to obviate system collapses, making sure your product is stable and reliable while its requirements are changing.',
  },
]

export default QAFAQ
