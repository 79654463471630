import { HomeSpecification } from '~/types/mock'

const QABusiness: HomeSpecification[] = [
  {
    icon: 'spriteIcon_74',
    title: 'Optimize testing processes',
    description:
      'Your software quality assurance process will gain the highest efficiency. So, it will be easy for you to scale it up or down according to the business needs.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Reduce costs',
    description:
      'Our high-priority aim of software quality assurance services is to assist you in defining and correcting issues early, where they cost less to tackle.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Enhance product quality',
    description:
      'After implementing our test execution operations, the software quality will be at the highest degree. So, this will gain users’ credibility in your solution.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'Faster delivery time',
    description:
      'Our experienced software quality assurance team uses the top software testing tools that dramatically speed up the product delivery time.',
  },
]

export default QABusiness
