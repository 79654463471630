import { AnchorMock } from '~/types/mock'

const Anchors: AnchorMock[] = [
  {
    anchor: 'Services',
  },
  {
    anchor: 'Use Cases',
  },
  {
    anchor: 'Benefits',
  },
  {
    anchor: 'Business',
  },
  {
    anchor: 'Results',
  },
  {
    anchor: 'Industries',
  },
  {
    anchor: 'Tech Stack',
  },
  {
    anchor: 'Testimonials',
  },
  {
    anchor: 'Free Quote',
  },
  {
    anchor: 'FAQ',
  },
]

export default Anchors
