import { NavTechStackMock, TechStackMock } from '~/types/mock'

export const NavTechStack: NavTechStackMock[] = [
  {
    title: 'Management',
  },
  {
    title: 'Manual testing',
  },
  {
    title: 'Automation testing',
  },
  {
    title: 'Working with NMA, APIs',
  },
]

export const TechStack: TechStackMock[] = [
  {
    title: 'Management',
    techStack: [
      { icon: 'jira', name: 'Jira' },
      { icon: 'trello', name: 'Trello' },
      { icon: 'monday', name: 'Monday' },
      { icon: 'asana', name: 'Asana' },
      { icon: 'googleDocs', name: 'Google Docs' },
      { icon: 'testrail', name: 'Testrail' },
      { icon: 'practitest', name: 'Practitest' },
    ],
  },
  {
    title: 'Manual testing',
    techStack: [
      { icon: 'chromedevtools', name: 'Chrome DevTools' },
      { icon: 'burpsuite', name: 'BurpSuite' },
      { icon: 'fiddler', name: 'Fiddler' },
      { icon: 'jmeter', name: 'JMeter' },
    ],
  },
  {
    title: 'Automation testing',
    techStack: [
      { icon: 'playwright', name: 'Playwright' },
      { icon: 'mailtrap', name: 'Mailtrap' },
      { icon: 'git', name: 'Git' },
    ],
  },
  {
    title: 'Working with NMA, APIs',
    techStack: [
      { icon: 'postman', name: 'Postman' },
      { icon: 'xcode', name: 'XCode' },
    ],
  },
]
