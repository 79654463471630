import { ExpertiseSectionMock } from '~/types/mock'

const QAWhenDoYouNeed: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_16',
    title: 'You wonder how to save costs on development',
    description:
      'Well-organized QA testing processes enable you to allocate your budget more strategically and define the bugs before they cost you money.',
  },
  {
    icon: 'spriteIcon_30',
    title: 'You aim for the product to meet users’ needs',
    description:
      'User experience can make or destroy your software. QA services help ensure that your end-users are satisfied and the web product covers all functionality gaps if any.',
  },
  {
    icon: 'spriteIcon_74',
    title: 'You think about converting users into returning clients',
    description:
      'The sustainability and stability of your web product system will affect your clients’ credibility. With the right testing strategy, they will return to you again and again.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'You want to make your app quality and reliable',
    description:
      'QA testing experts help maintain your solution’s high-quality standards. They will assist in detecting and preventing software vulnerabilities in advance.',
  },
]

export default QAWhenDoYouNeed
