import { ExpertiseSectionMock } from '~/types/mock'

const QAServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'QA consulting',
    description:
      'We enhance the effectiveness of the QA process or refine the existing QA services.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Test automation',
    description:
      'Automated testing helps increase web development productivity and expand test coverage.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'End-to-end testing',
    description:
      'Software testing ensures your software solution flow behaves as it should.',
  },
  {
    icon: 'sprite3DIcon_5',
    title: 'API testing',
    description:
      'Ensures that your app programming interfaces meet all reliability and performance expectations.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Security testing',
    description:
      'Our QA testing team helps to cover security gaps and weaknesses in your software.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Performance testing',
    description:
      'Our in-house QA team checks how the system performs under a specific workload.',
  },
]

export default QAServices
