import { ExpertiseSectionMock } from '~/types/mock'

const QABenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_16',
    title: 'Costs saving',
    description:
      'Codica’s QA services reduce costs by enhancing workflow efficiency by solving problems quickly.',
  },
  {
    icon: 'spriteIcon_11',
    title: 'Better user experience',
    description:
      'Usability means the efficiency of use, ease of learning, and user satisfaction. QA tests ensure the usability of UX.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Great security',
    description:
      'Our QA services and security testing ensure that specific security requirements be followed.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Enhanced users’ confidence',
    description:
      'Codica’s quality assurance approach is the foundation for user satisfaction and confidence.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Breakdowns avoiding',
    description:
      'Our QA process comprises following standards, guidelines, and practices to prevent system breakdowns.',
  },
  {
    icon: 'spriteIcon_67',
    title: 'Strengthens the brand reputation',
    description:
      'With our professional QA services, your digital business will be based on quality and user trust.',
  },
]

export default QABenefits
