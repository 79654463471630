import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useQualityAssuranceStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-52.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-53.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoReviewImage: file(
        relativePath: { eq: "avatars/clients/large-photo/client-pmk.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientRef: file(
        relativePath: { eq: "avatars/clients/large-photo/client-ref.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaOleksiiPhoto: file(
        relativePath: { eq: "avatars/QA-Oleksii.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useQualityAssuranceStaticQuery
